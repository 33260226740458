// 选择企业组件
<template>
  <div class="select-enterprise">
    <el-drawer
      size="100%"
      :with-header="false"
      v-model="drawer"
      custom-class="select-drawer"
      :direction="'btt'"
      :before-close="handleClose"
    >
      <Enterprise
        :key="derawerNumber"
        v-if="drawer"
        @itemClick="itemClick"
        @onBack="onBack"
      />
    </el-drawer>
  </div>
</template>

<script>
import Enterprise from "../views/register/enterprise";
export default {
  props: ["drawer"],
  data() {
    return {
      derawerNumber: 0,
    };
  },
  methods: {
    itemClick(item) {
      this.$emit("itemClick", item);
      this.derawerNumber++;
    },
    onBack() {
      this.$emit("onBack");
      this.derawerNumber++;
    },
    handleClose(done) {
      done();
      this.derawerNumber++;
    },
  },
  mounted() {},
  components: {
    Enterprise,
  },
};
</script>

<style>
.select-drawer {
  max-width: 500px;
  margin: 0 auto;
}
.select-drawer .el-drawer__body {
  height: 0;
}
</style>
