<template>
  <div class="register-sign-up">
    <div class="register-sign-up-pr">
      <div class="header"></div>
      <div class="container">
        <!-- label-width="100px" -->
        <el-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          label-position="top"
        >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-select
              v-model="form.gender"
              placeholder="请选择性别"
              style="width: 100%"
            >
              <el-option label="男" value="m"></el-option>
              <el-option label="女" value="f"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号码" prop="idCard">
            <el-input
              v-model="form.idCard"
              placeholder="请输入身份证号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email">
            <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="orgTitle">
            <el-select
              style="width: 100%"
              v-model="form.orgTitle"
              remote
              placeholder="请输入所属部门"
              :remote-method="remoteMethod"
              :loading="loading"
              @click="showDrawer"
            >
            </el-select>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="身份证正面" prop="idCardFImage">
                <!-- drag -->
                <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  @change="positiveUpload"
                  :auto-upload="false"
                >
                  <div class="uploader-content-div" v-if="!form.idCardFImage">
                    <img src="../../assets/imgs/id_01.png" alt="" />
                  </div>
                  <div class="uploader-imgContent-div" v-else>
                    <img :src="form.idCardFImage" />
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证反面" prop="idCardBImage">
                <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  @change="reverseSideUpload"
                  :auto-upload="false"
                >
                  <div class="uploader-content-div" v-if="!form.idCardBImage">
                    <img src="../../assets/imgs/id_02.png" alt="" />
                  </div>
                  <div class="uploader-imgContent-div" v-else>
                    <img :src="form.idCardBImage" />
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="footer">
          <el-button
            type="primary"
            round
            @click="confirm"
            :loading="isSubmit"
            style="margin: 15px 0 30px; width: 100%"
            >确认</el-button
          >
        </div>
      </div>
    </div>
    <selectEnterprise
      @itemClick="itemClick"
      @onBack="isShowDrawer = false"
      :drawer="isShowDrawer"
    />
  </div>
</template>
<script>
import selectEnterprise from "../../components/selectEnterprise";
export default {
  name: "register-sign-up",
  data() {
    return {
      isShowDrawer: false, // 是否显示抽屉（选择企业）
      idCardFImage: "", //  保存正面身份证图片
      idCardBImage: "", //  保存反面身份证图片
      itemData: {}, // 选中组织数据
      isSubmit: false, // 是否提交
      form: {
        name: "",
        gender: "m",
        mobile: "",
        idCard: "",
        email: "",
        orgTitle: "",
        idCardFImage: "",
        idCardBImage: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        idCard: [{ required: true, message: "请输入身份证", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur"],
          },
        ],
        orgTitle: [
          { required: true, message: "请输入所属部门", trigger: "blur" },
        ],
        idCardFImage: [
          { required: true, message: "请上传身份证正面", trigger: "blur" },
        ],
        idCardBImage: [
          { required: true, message: "请上传身份证反面", trigger: "blur" },
        ],
      },
      uploadData: {
        type: "idf",
      },
    };
  },
  created() {
    this.form.mobile = this.$route.query?.phone || "";
  },
  methods: {
    showDrawer(e) {
      this.isShowDrawer = true;
      console.log(this.$store.state);
    },
    // 选择企业回调
    itemClick(item) {
      this.itemData = item;
      this.form.orgTitle = item.title;
      this.isShowDrawer = false;
    },
    handleAvatarSuccess(file, type) {
      if (file.response) {
        this.information[type] = file.response.path;
        this.dialogInfo[type] = URL.createObjectURL(file.raw);
      }
    },
    // 提交正面身份证
    positiveUpload(files) {
      const file = files.raw;
      this.beforeAvatarUpload(file, 1);
    },
    // 提交反面身份证
    reverseSideUpload(files) {
      const file = files.raw;
      this.beforeAvatarUpload(file, 2);
    },
    beforeAvatarUpload(file, type) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG) {
        this.$message.error("图片只能是 jpg jpeg png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      let formData = new FormData();
      formData.append("file", file);

      if (type == 1) {
        formData.append("type", "idf");
      } else {
        formData.append("type", "idb");
      }
      this.$https
        .postWithFile("/api/upload", formData)
        .then((res) => {
          if (res.success) {
            if (type == 1) {
              this.form.idCardFImage = res.data.url;
              this.idCardFImage = res.data.path;
            } else {
              this.form.idCardBImage = res.data.url;
              this.idCardBImage = res.data.path;
            }
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
          // this.$message.error("上传失败");
        });
      return isJPG && isLt2M;
    },
    selectImg(type) {
      this.uploadData.type = type;
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.isSubmit = true;
          this.$https
            .post("/api/registerByMobile", {
              name: this.form.name,
              gender: this.form.gender,
              mobile: this.form.mobile,
              idCard: this.form.idCard,
              email: this.form.email,
              orgId: this.itemData.id,
              idCardFImage: this.idCardFImage,
              idCardBImage: this.idCardBImage,
              verifyCode: this.$route.query?.code || "",
            })
            .then((res) => {
              this.isSubmit = false;
              if (res.success) {
                this.$store.commit("setUser", res?.data);
                this.$storage.setStorage([
                  {
                    key: "userInfo",
                    value: res?.data,
                  },
                ]);
                this.$storage.setCookie("token", res?.data?.token);
                this.$confirm("默认密码为身份证后6位", "注册成功", {
                  confirmButtonText: "去登录",
                  showCancelButton: false,
                  showClose: false,
                  center: true,
                })
                  .then(() => {
                    sessionStorage.removeItem("userInfo");
                    this.$router.push({
                      path: "/login",
                    });
                  })
                  .catch(() => {
                    sessionStorage.removeItem("userInfo");
                    this.$router.push({
                      path: "/login",
                    });
                  });
              } else {
                if (res?.message) {
                  this.$message.error(res?.message);
                }
              }
            })
            .catch((error) => {
              this.isSubmit = false;
              this.$message.error(error.message);
              // this.$message.error("提交失败");
            });
        } else {
          return false;
        }
      });
    },
  },
  components: {
    selectEnterprise,
  },
};
</script>
<style lang="less">
.register-sign-up {
  position: relative;
  height: 100%;
  width: 100%;
  // background-color: #f4f4f4;
  overflow: auto;
  .register-sign-up-pr {
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
  }
  .avatar-uploader {
    width: 100%;
  }
  .uploader-content-div {
    width: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    img {
      width: 100%;
      border-radius: 5px;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 146px;
    line-height: 146px;
    text-align: center;
  }
  .uploader-imgContent-div {
    max-width: 100%;
    // height: 146px;
    img {
      max-width: 100%;
      border-radius: 5px;
    }
  }
  .el-form-item {
    margin-bottom: 15px;
  }
  .el-upload {
    width: 100%;
  }
  .el-form-item__label {
    padding: 0 !important;
  }
  .header {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #677897;
    font-weight: bold;
  }
  .container {
    padding: 20px 20px;
    width: 100%;
  }
  .footer {
    text-align: center;
  }
}
</style>
